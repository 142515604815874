<template>
  <div class="daily-sales-report">
    <EbpCard>
      <!-- Filters -->
      <b-row>
        <!-- Date range -->
        <b-col :md="$isAdmin ? 3 : 4" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("date-range") }}</p>
          <DatePicker
            v-model="filters.date_range"
            range
            :placeholder="$t('date-range')"
            valueType="format"
            :clearable="false"
            :disabled-date="isDateSelectable"
          />
        </b-col>

        <!-- Book filter -->
        <b-col md="4" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("books") }}</p>

          <AsyncTreeSelect
            :disabled="loading"
            v-model="filters.titles"
            multiple
            :async="$isAdmin"
            :options="titlesDropdown"
            internal-search
            internal-search-key="label"
          />
        </b-col>

        <!-- Date range -->
        <b-col md="4" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("territories") }}</p>
          <TreeSelect
            v-model="filters.territories"
            :options="territories"
            multiple
          />
        </b-col>

        <!-- Customer selection -->
        <b-col md="3" class="mb-4 mb-md-0" v-if="$isAdmin">
          <p class="mb-1">{{ $t("client") }}</p>
          <AsyncTreeSelect
            v-model="filters.clients"
            :async="$isAdmin"
            action="users/search"
            track-by="id"
            label="name"
            :disabled="loading"
          />
        </b-col>
      </b-row>
    </EbpCard>

    <!-- Graph -->
    <EbpCard :loading="loading">
      <apexchart
        width="100%"
        type="area"
        :series="series"
        :options="chartOptions"
        height="300px"
      />
    </EbpCard>

    <!-- Data table -->
    <AdvancedDataTable
      :items="records"
      :headers="headers"
      :paginate="false"
      :showLimit="false"
      :showTotal="false"
      hideActions
      sticky-header="60vh"
      :show-filters="false"
      sortable
      :search="false"
    >
      <!-- Book -->
      <template #cell(book_id)="{ item }">
        <span v-tooltip="item.book.title">{{
          truncate(item.book.title, 20)
        }}</span>
      </template>

      <!-- Sales channel -->
      <template #cell(sales_channel_id)="{ item }">
        {{ item.sales_channel.name }}
      </template>

      <!-- Territory -->
      <template #cell(territory_id)="{ item }">
        <span v-tooltip.top-center="item.territory.name">{{
          item.territory.code
        }}</span>
      </template>

      <!-- Date of sale -->
      <template #cell(date_of_sale)="{ item }">
        {{ formatDate(item.date_of_sale) }}
      </template>

      <!-- Units sold -->
      <template #cell(units_sold)="{ item }">
        {{ formatNumber(item.sold) }}
      </template>

      <!-- Units returned -->
      <template #cell(units_returned)="{ item }">
        {{ formatNumber(item.returned) }}
      </template>

      <!-- Net sales -->
      <template #cell(net_sales)="{ item }">
        {{ formatNumber(item.net) }}
      </template>
    </AdvancedDataTable>
  </div>
</template>

<script>
import truncate from "@/helpers/truncate";
import { mapGetters } from "vuex";
import DatePicker from "../../components/core/DatePicker";
import EbpCard from "../../components/core/ebp-card";
import TreeSelect from "../../components/core/tree-select";
import { subDays, addDays, format, differenceInDays } from "date-fns";
import bookHelper from "../../helpers/book";
import AdvancedDataTable from "../../components/advanced-data-table";
import { firstBy } from "thenby";
import AsyncTreeSelect from "@/components/AsyncTreeSelect";
import graphHelper from "@/helpers/graph";

export default {
  name: "DailySalesReport",
  components: {
    DatePicker,
    TreeSelect,
    EbpCard,
    AdvancedDataTable,
    AsyncTreeSelect
  },
  data() {
    return {
      filters: {
        date_range: null,
        territories: [],
        titles: [],
        clients: []
      },
      loading: false,
      titles: [],
      records: [],
      series: [],
      headers: [
        "book_id",
        "sales_channel_id",
        "territory_id",
        "date_of_sale",
        "units_sold",
        "units_returned",
        "net_sales"
      ]
    };
  },
  mounted() {
    // By default date should be last 7 days
    const formatString = "dd-MMM-yyyy";
    this.filters.date_range = [
      format(subDays(Date.now(), 7), formatString),
      format(Date.now(), formatString)
    ];

    this.$store.dispatch("ancillaries/getTerritories");
  },
  computed: {
    ...mapGetters("ancillaries", ["territories"]),
    titlesDropdown() {
      return this.titles
        .map(t => ({
          id: t.id,
          label: t.title
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    chartOptions() {
      return {
        yaxis: {
          title: {
            text: this.$t("sales-downloads-text"),
            style: {
              fontSize: "14px",
              fontFamily: "SF UI Text"
            }
          }
        },
        colors: this.$colors,
        xaxis: {
          categories: this.dates()
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          curve: "smooth"
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          },
          style: "hollow"
        },
        chart: {
          toolbar: {
            export: {
              csv: {
                filename: `'daily-sales-report'`
              }
            }
          }
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function(val) {
                  return val;
                }
              }
            }
          ]
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.05,
            stops: [42, 100, 100, 100]
          }
        }
      };
    }
  },
  methods: {
    truncate,
    isDateSelectable: date => {
      const diff = differenceInDays(date, Date.now());
      return diff < -360 || diff >= 0;
    },
    dates() {
      if (!this.filters.date_range || !this.filters.date_range.length) {
        return [];
      }
      const startDate = new Date(this.filters.date_range[0]);
      const endDate = new Date(this.filters.date_range[1]);

      const daysArray = new Array(
        Math.abs(differenceInDays(startDate, endDate)) + 1
      ).fill(0);

      return daysArray.map((_, i) => format(addDays(startDate, i), "dd-MMM"));
    },
    async get(type = "records") {
      this.loading = true;
      try {
        const res = await this.$store.dispatch(
          type === "records" ? "dailySaleRecords/all" : "books/getByDailySales",
          {
            date_range: this.filters.date_range.join(","),
            territories: bookHelper
              .formatTerritoriesForAPI(this.filters.territories)
              .join(","),
            titles: this.filters.titles.join(","),
            user_ids: this.filters.clients.join(",")
          }
        );

        const isR = type === "records";
        this[isR ? "series" : "titles"] = isR
          ? graphHelper.formatSalesRecords(res.data, this.filters.date_range)
          : res.data;
        if (isR)
          this.records = res.data.sort(
            firstBy(
              (a, b) => new Date(b.date_of_sale) - new Date(a.date_of_sale)
            ).thenBy((a, b) => a.book.title.localeCompare(b.book.title))
          );

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    }
  },
  watch: {
    "filters.date_range"() {
      this.get("titles");
      this.get();
    },
    "filters.territories"() {
      this.get("titles");
      this.get();
    },
    "filters.clients"() {
      this.get("titles");
      this.get();
    },
    "filters.titles"() {
      this.get();
    }
  }
};
</script>

<style></style>;
